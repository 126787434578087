<template>
  <form class="md-float-material" @submit.prevent="addVerification()">
    <div class="auth-box card">
      <div class="card-block">
        <div class="form-group form-primary">
          <p style="font-size: 15px;" v-html="$t('addVerification.gotolink',[telegramLink])"></p>
          <label class="form-label" for="telegram_id">{{ $t('settings.enter_Telegram_ID') }}</label>
          <div class="input-group">
            <input id="telegram_id"
                   style="padding-left: 5px"
                   type="text"
                   class="form-control"
                   placeholder="123456789"
                   maxlength="17"
                   @input="typeTelegram"
                   v-model="payload.telegram_id">
            <b-button variant="primary" :disabled="$v.$invalid || this.loading" type="submit">
              {{ this.$route.name !== 'VerificationAdd' ? $t('addVerification.change') :$t('monitoring.add') }}
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
            </b-button>
          </div>
          <div class="text-success" v-if="success.length">{{ $t('rules.'+success) }}</div>
          <div class="text-danger" v-if="error.length">{{ $t('rules.'+error) }}</div>
          <rule-validation v-for="(rule, key) in validationRules"
                           :key="`validation${key}`"
                           v-if="!$v.payload.telegram_id[key]"
                           model="telegram_id"
                           :rule="key"
                           :raw="$v.payload.telegram_id"/>
          <p style="font-size: 15px">{{$t('addVerification.account_plans')}}</p>
        </div>

      </div>
    </div>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from "vuelidate/lib/validators";
import RuleValidation from "@/components/RuleValidation.vue";
import env from '@/configs/env.json';

export default {
  name: "AddVerificationForm",
  components: {RuleValidation},
  mixins: [validationMixin],
  props: ['isSettings'],
  setup() {
    return {
      telegramLink: env.telegramLink,
      validationRules: {
        required
      }
    }
  },
  data() {
    return {
      payload: {
        telegram_id: null
      },
      loading: false,
      error: '',
      success: ''
    }
  },
  validations() {
    return {
      payload: {
        telegram_id: this.validationRules
      },
    }
  },
  methods: {
    addVerification() {
      this.loading = true;
      this.$store.dispatch('auth/addVerification', this.payload)
          .then(data => {
            if(this.$route.name === 'VerificationAdd') {
              this.$router.push({name: 'verification'})
            } else {
              if (!data.success){
                this.error = 'wrong_telegram_id'
              } else {
                this.success = 'successTelegramChange'
              }
            }
          }).catch(e => {
            if(e.response.status === 400) {
              this.error = 'wrong_telegram_id'
            }
          }).finally(() => {
            this.loading = false;
      })
    },
    typeTelegram() {
      this.error = '';
      this.success = '';
      this.$v.payload.telegram_id.$touch()
    }
  },
  watch: {
    'payload.telegram_id': {
      handler(value) {
        this.payload.telegram_id = parseInt(
            value.toString().replace(/[^0-9]/i, '')
        ) || '';
      }
    }
  }
}
</script>

<style scoped>

</style>