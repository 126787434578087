<template>
  <section class="login-block">
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="settings-login-container">
          <div class="card">
            <div class="card-body p-30">
              <!-- Authentication card start -->
              <!-- <add-verification-form/> -->
              <!-- end of form -->
              <ul class="tipses-way m-b-10">
                <li>
                  <div class="d-flex justify-content-between flex-md-row flex-column-reverse  m-b-15">
                    <div class="description">
                      <p>{{ $t('addVerification.openTotp') }}</p>
                      <div class="row verification-text p-t-10">
                        <div class="col-lg-4 d-flex m-b-10">
                          <div class="icon-wrapper android-wrapper">
                            <android-icon color="#ffffff"></android-icon>
                          </div>
                          <ul>
                            <li><a :href="lastApk" download>GDS Client</a></li>
                            <li><a :href="linksApps.android.google" target='_blank'>Google Authenticator</a></li>
                            <li><a :href="linksApps.android.microsoft" target='_blank'>Microsoft Authenticator</a></li>
                            <li><a :href="linksApps.android.lastPass" target='_blank'>LastPass Authenticator</a></li>
                          </ul>
                        </div>
                        <div class="col-lg-4 d-flex m-b-10">
                          <div class="icon-wrapper apple-wrapper">
                            <apple-icon color="#ffffff"></apple-icon>
                          </div>
                          <ul>
                            <li><a :href="linksApps.ios.google" target='_blank'>Google Authenticator</a></li>
                            <li><a :href="linksApps.ios.microsoft" target='_blank'>Microsoft Authenticator</a></li>
                            <li><a :href="linksApps.ios.lastPass" target='_blank'>LastPass Authenticator</a></li>
                          </ul>
                        </div>
                        <div class="col-lg-4 d-flex m-b-10">
                          <div class="icon-wrapper browser-wrapper">
                            <div class="icon-holder">
                              <img src="/images/browser.png" class="" alt="">
                            </div>
                          </div>
                          <ul>
                            <li><a href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai" target="_blank">Google Chrome, Opera</a></li>
                            <li><a href="https://apps.apple.com/ua/app/authen/id1602945200?l=ru&mt=12" target="_blank">Safari</a></li>
                            <li><a href="https://microsoftedge.microsoft.com/addons/detail/ocglkepbibnalbgmbachknglpdipeoio" target="_blank">Microsoft Edge</a></li>
                            <li><a href="https://addons.mozilla.org/en-US/firefox/addon/auth-helper?src=external-website" target="_blank">Mozilla Firefox</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center justify-content-md-end m-b-10 qr-wrapper">
                      <qrcode-vue :value="`otpauth://totp/${domen}:${login}?secret=${hash}`" :size="160" level="L" />
                    </div>
                  </div>
                </li>
                <li><p>{{ $t('addVerification.camera') }}</p>
                  <p class="text-center" style="max-width: 480px; font-style: italic;">{{ $t("addVerification.or") }}</p>
                  <p>{{ $t('addVerification.click_copy') }}</p>
                </li>
                <li><p>{{ $t("addVerification.on_success") }}</p></li>
                <li><p>{{ $t('addVerification.you_will_have') }}</p></li>
              </ul>
              <div class="d-flex justify-content-between footer-btns">
                <div class="btn-group">
                  <button class="btn btn-primary" @click="addToClipBoard"><i class="feather icon-copy"></i> {{
                    $t('addVerification.copy') }}</button>
                </div>
                <router-link :to="{ name: 'verification' }" class="btn btn-primary">{{ $t('ui.continue') }} <i
                    class="feather icon-arrow-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </section>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import AddVerificationForm from "@/components/AddVerificationForm.vue";
import AppleIcon from "@/components/icons/appleIcon"
import AndroidIcon from "@/components/icons/androidIcon"
import { mapGetters } from "vuex";
export default {
  name: "VerificationAdd",
  components: { AddVerificationForm, QrcodeVue, AppleIcon, AndroidIcon },
  setup() {
    return {
      hash: localStorage.getItem('secretHash'),
      login: localStorage.getItem('login')
    }
  },
  methods: {
    addToClipBoard() {
      navigator.clipboard.writeText(this.hash).then(() => {
        this.$toast.success( this.$t('addVerification.copy_text') , {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        console.log('Content copied to clipboard');
      }, () => {
        console.error('Failed to copy');
      });
    }
  },
  computed: {
    linksApps() {
      return {
        ios: {
          google: 'https://apps.apple.com/app/google-authenticator/id388497605',
          microsoft: 'https://apps.apple.com/app/microsoft-authenticator/id983156458',
          lastPass: 'https://apps.apple.com/app/lastpass-authenticator/id1079110004'
        },
        android: {
          google: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
          microsoft: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
          lastPass: 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator'
        }
      }
    },
    domen() {
      return location.href.includes('demodata') ? 'demodata' : 'GDS'
    },
    ...mapGetters("auth", ['lastApk']),
  }
}
</script>

<style scoped>
.verification-text p {
  margin-bottom: 5px;
}

.verification-text a {
  color: #4099ff;
  text-decoration: underline;
}

.verification-text a:hover {
  text-decoration: none;
}
.icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.icon-wrapper .icon-holder {
  width: 25px;
  height: 25px;
}
.icon-wrapper img {
  width: 100%;
}
@media (max-width: 960px) {
  .icon-wrapper {
    width: 40px;
    height: 40px;
  }
  .icon-wrapper .icon-holder {
    width: 20px;
    height: 20px;
  }
}
.android-wrapper {
  background: rgb(141, 194, 48);
}
.apple-wrapper {
  background: rgb(148, 148, 148);
}
.browser-wrapper {
  background: rgb(113, 216, 254);
}
@media (min-width: 768px) {
  .tipses-way .description {
    padding-right: 20px;
  }
}

.tipses-way p {
  margin-bottom: 10px;
}

.tipses-way > li {
  margin:0; 
  padding: 10px 0 10px 40px;
  counter-increment: section;
  position: relative;
}
.tipses-way > li::before {
  content: counter(section);
  right: 100%; 
  position: absolute;
  border-radius: 50%;
  line-height: 20px;
  vertical-align: middle;
  height: 30px;
  width: 30px;
  border: 1px solid #4099ff;
  text-align:center;
  color: #4099ff;
  font-size: 16px;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

@media (max-width: 767px) {
  .tipses-way > li:first-child::before {
    top: 180px;
  }
  .qr-wrapper {
    margin-left: -30px;
  }
}

.tipses-way > li:first-child {
  border-top: 0;
  border-top-right-radius:0;
  border-top-left-radius:0;
}

.tipses-way > li:last-child {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
}
.settings-login-container {
  width: 100%;
  max-width: 960px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 961px) {
  .verification-text > div+div {
    padding-left: 0;
  }
}
@media (max-width: 380px) {
  .footer-btns .btn {
    font-size: 12px;
  }
}
</style>
